<main class="main-container" *ngIf="isLogIn">
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-body">
            <aside class="sidebarMenu">
            <div class="sideBarUst d-flex justify-content-center">
                <div class="logo">
                    <img src="../../../assets/ampersand-logo.png">
                </div>
            </div>
            <hr class="opacity-100 ms-3 me-3 m-0" style="border-top: 1px solid #864f20;">

            <div class="sideBarOrta mt-3">

                <!-- superadmin -->
                <ul class="nav flex-column">
                    <li class="nav-item" *ngIf="user.role == 'superadmin'" data-bs-dismiss="offcanvas">
                        <a class="nav-link" routerLink="superadmin/survey-detail" routerLinkActive="active" href="#">
                            <i class="pi pi-microsoft mb-0"></i>
                            <span class="lables">Dashboard</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user.role == 'superadmin'" data-bs-dismiss="offcanvas">
                        <a class="nav-link" routerLink="superadmin/new-survey-admin" routerLinkActive="active" href="#">
                            <i class="bi bi-person-circle mb-0"></i>
                            <span class="lables">User Management</span>
                        </a>
                    </li>
                    <!-- surveyadmin -->
                    <li class="nav-item" *ngIf="user.role == 'surveyadmin'" data-bs-dismiss="offcanvas">
                        <a class="nav-link" routerLink="surveyadmin/survey-dashboard" routerLinkActive="active" href="#">
                            <i class="pi pi-microsoft mb-0"></i>
                            <span class="lables">Dashboard</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user.role == 'surveyadmin'" data-bs-dismiss="offcanvas">
                        <a class="nav-link" routerLink="surveyadmin/survey_assign" routerLinkActive="active" href="#">
                            <i class="bi bi-bookmarks-fill h4 mb-0"></i>
                            <span class="lables">Survey Admin View</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user.role == 'surveyadmin'" data-bs-dismiss="offcanvas">
                        <a class="nav-link" [routerLink]="['/surveyadmin/add-survey-questions', 'new']" routerLinkActive="active">
                            <i class="bi bi-bookmark-plus-fill mb-0"></i>
                            <span class="lables">Create Survey Form</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user.role == 'surveyadmin'" data-bs-dismiss="offcanvas">
                        <a class="nav-link" routerLink="surveyadmin/view-survey-form" routerLinkActive="active">
                            <i class="bi bi-chat-right-text mb-0"></i>
                            <span class="lables">View Survey Forms</span>
                        </a>
                    </li>
                    <li class="nav-item" data-bs-toggle="collapse" href="#collapseExample" role="button"
                        *ngIf="user.role == 'superadmin'" data-bs-dismiss="offcanvas">
                        <a class="nav-link">
                            <i class="bi bi-chat-right-text mb-0"></i>
                            <span class="lables">Master Management</span>
                        </a>
                    </li>
                    <div class="collapse collapsed-dropdown" id="collapseExample">
                        <li class="nav-item" data-bs-dismiss="offcanvas">
                            <a class="nav-link text-start" routerLink="superadmin/block" routerLinkActive="active"
                                href="#">
                                <span class="lables">Block</span>
                            </a>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas">
                            <a class="nav-link text-start" routerLink="superadmin/district" routerLinkActive="active"
                                href="#">
                                <span class="lables">District</span>
                            </a>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas">
                            <a class="nav-link text-start" routerLink="superadmin/division" routerLinkActive="active"
                                href="#">
                                <span class="lables">Division</span>
                            </a>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas">
                            <a class="nav-link text-start" routerLink="superadmin/school" routerLinkActive="active"
                                href="#">
                                <span class="lables">School</span>
                            </a>
                        </li>
                    </div>
                    <!-- Block coordinator -->
                <li class="nav-item" *ngIf="user.role == 'block'" data-bs-dismiss="offcanvas">
                    <a class="nav-link" routerLink="block-coordinator/projects" routerLinkActive="active" href="#">
                        <i class="bi bi-person-add h4 mb-0"></i>
                        <span class="lables" *ngIf="user.role == 'block'">Block Coordinator</span>
                    </a>
                </li>
               <!-- SME COORDINATOR -->
                <li class="nav-item" *ngIf="user.role == 'SME'" data-bs-dismiss="offcanvas">
                    <a class="nav-link" routerLink="sme-coordinator/project" routerLinkActive="active" href="#">
                        <i class="bi bi-person-add h4 mb-0"></i>
                        <span class="lables">SME Coordinator</span>
                    </a>
                </li>
                <!-- District Coordinator -->
                <li class="nav-item" *ngIf="user.role == 'district'" data-bs-dismiss="offcanvas">
                    <a class="nav-link" routerLink="district-coordinator/project" routerLinkActive="active" href="#">
                        <i class="bi bi-person-add h4 mb-0"></i>
                        <span class="lables">District Coordinator</span>
                    </a>
                </li>
                <!-- Division Coordinator -->
                <li class="nav-item" *ngIf="user.role == 'division'" data-bs-dismiss="offcanvas">
                    <a class="nav-link" routerLink="division-coordinator/project" routerLinkActive="active" href="#">
                        <i class="bi bi-person-add h4 mb-0"></i>
                        <span class="lables">Division Coordinator</span>
                    </a>
                </li>
            </ul>
            </div>
        </aside>
        </div>
    </div>

    <!-- Page Content  -->
    <div class="page-container" id="content">
        <!-- Navbar -->
        <nav class="navbar navbar-expand-xl navbar-light">
            <div class="d-flex align-items-center justify-content-between w-100">
                <div class="d-flex align-items-center">
                    <button class="navbar-toggler d-xl-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <h4 class="header-font mb-0 d-none d-md-block">SURVEY MANAGEMENT</h4>
                    <h4 class="header-font mb-0 d-block d-md-none">SARA</h4>
                </div>
                <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo01">
                    <ul class="navbar-nav flex-row">
                        <li class="nav-item" *ngIf="user.role == 'block'||user.role == 'division'||user.role == 'district'||user.role=='SME'">
                            <h6>{{user.name}}</h6>
                        </li>
                        <li class="vr" *ngIf="user.role == 'block'||user.role == 'division'||user.role == 'district'||user.role=='SME'"></li>
                        <li class="nav-item">
                            <i class="bi bi-bell-fill icons" title="Notifications"></i>
                        </li>
                        <li class="nav-item">
                            <i class="bi bi-person-circle icons" title="Profile"></i>
                        </li>
                        <li class="nav-item">
                            <i class="pi pi-sign-out icons" title="Logout" (click)="logout()"></i>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <router-outlet></router-outlet>


        <footer>
            <span>powered by HubbleHox</span>
        </footer>
    </div>
    <!-- Page Content  -->

</main>

<div *ngIf="!isLogIn">
    <router-outlet></router-outlet>
</div>
