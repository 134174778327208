import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpServiceService } from './Service/http-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'mh-survey';
  isLogIn: boolean = false;

  status: boolean = true;
  statusLink: boolean = true;
  user: any

  constructor(private router: Router, private htttpService: HttpServiceService) {
    this.user = JSON.parse(sessionStorage.getItem('userProfile')!);
    if (!this.user) {
      this.isLogIn = false;
      this.router.navigate(['']);
    } else {
      this.isLogIn = true;
    }
  }

  ngOnInit() {
    this.htttpService.getLoggedIn().subscribe((res: boolean) => {
      this.isLogIn = res;
      if (this.isLogIn) {
        this.user = JSON.parse(sessionStorage.getItem('userProfile')!);
      }
    })
  }

  logout() {
    sessionStorage.clear();
    this.isLogIn = false;
    this.router.navigate(['']);
  }

}
