import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommunicationService } from 'src/app/Service/communication.service';
import { HttpServiceService } from 'src/app/Service/http-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent {

  loginForm: any = FormGroup
  submitted: boolean = false;
  loginModel: any
  isLoading: boolean = false

  password: string = '';
  isPasswordVisible: boolean = false;

  constructor(private fb: FormBuilder, private httpService: HttpServiceService, private router: Router, private messageService: MessageService, private communicationService: CommunicationService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.checkIfAlreadyLogin();
    this.InitilizeValidation()
  }

  InitilizeValidation() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  get f() { return this.loginForm.controls; }

  checkIfAlreadyLogin() {
    const user = JSON.parse(sessionStorage.getItem('userProfile') || '{}');
    if (user && user.id) {
      if (user.role == "superadmin") {
        this.router.navigateByUrl('/superadmin/survey-detail');
      } else if (user.role == 'surveyadmin') {
        this.router.navigateByUrl('/surveyadmin/survey-dashboard');
      } else if (user.role == 'block') {
        this.router.navigateByUrl('/block-coordinator/projects');
      }
      else if (user.role == 'district') {
       this.router.navigateByUrl('/district-coordinator/project');
      }else if (user.role == 'division') {
        this.router.navigateByUrl('/division-coordinator/project');
       } else if (user.role == 'SME') {
        this.router.navigateByUrl('/sme-coordinator/project');
      }
    } else {
      this.httpService.setLoggedIn(false);
    }
  }

  submitForm() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.login()
  }

  login() {
    this.isLoading = true
    this.loginModel = this.loginForm.value;
    this.spinner.show();
    this.httpService.post('auth/login', this.loginModel).subscribe((data: any) => {
      if (data) {
        if (data.user.role == 'user') {
          this.messageService.add({ severity: 'error', summary: 'Role has not been assigned', detail: '' });
        } else {
          this.messageService.add({ severity: 'success', summary: 'Success fully Login', detail: '' });
          this.communicationService.setLoginDetails(data);
          this.httpService.setLoggedIn(true);
          if (data.user.role == "superadmin") {
            this.router.navigateByUrl('/superadmin/survey-detail');
          } else if (data.user.role == 'surveyadmin') {
            this.router.navigateByUrl('/surveyadmin/survey-dashboard');
          }else if (data.user.role == 'division') {
            this.router.navigateByUrl('/division-coordinator/project');
           } else if (data.user.role == 'block') {
            this.router.navigateByUrl('/block-coordinator/projects');
          }else if (data.user.role == 'district') {
            this.router.navigateByUrl('/district-coordinator/project');
          }else if (data.user.role == 'SME') {
            this.router.navigateByUrl('/sme-coordinator/project');
          }
        }
        this.isLoading = false;
        this.spinner.hide()
      }
    }, (error) => {
      this.spinner.hide()
      this.messageService.add({ severity: 'error', summary: 'Invalid Email Or Password', detail: '' });
      this.isLoading = false;
    })
  }

}





